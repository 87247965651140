$(
	 function() {
		 mailer.assignMailer("a.mailer, .add-options a.mail-to");
	 }
);
var mailer = {
	itemOptions : {},
	mailFormUrl : '',
	mailProcessUrl: '',
	domainName: null,
	messages : {
		cancel : 'Cancel',
		send : 'Send Information'
	},

	getSvgPdf: function(callBack) {
		if (!parent.window.svgEditor) {
			if (callBack) {
				callBack(null, null);
			}
			return false;
		}

		var outFileName = $('div.name span.name').html() + '.pdf';

		parent.window.svgPdf.makePdf(
			'all',
			function(blob) {
				var reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onload = function (e) {
					if (callBack) {
						callBack(outFileName, e.target.result);
					}
				}
			}
		);
	},


	assignMailer : function(selector) {
		$(selector).click(
			 function(e) {
			 	var $this = $(this);

				 mailer.$item = $this.closest('.item, .product-view');
				 var $divTitle = mailer.$item.find('.name');
				 var title = '';

				 if ($('.product-view div.name span.name').length > 0) {
					 title = $('.product-view div.name span.name').html();
				 } else {
					 if ($divTitle.find('a').length > 0) {
						 title = $divTitle.find('a').html();
					 } else {
						 title = $divTitle.html();
					 }
				 }

				 var href = $this.attr('href');
				 if (!href && app.prodInfo) {
					href = String(document.location);
				 }

				 mailer.itemOptions = {
					 title: title,
					 href: href
				 };

				 mailer.openWindow(mailer.itemOptions);
				 e.preventDefault();
				 return false;
			 }
		)
	},
	blockWindow : function(doBlock, callBack, blockId, zIndex) {
		if (!blockId) {
			blockId = "udu-mailer-block-div";
		}
		if (!zIndex) {
			zIndex = 5000;
		}
		var $block = $("#" + blockId);
		if ($block.length == 0) {
			$("<div id=\"" + blockId + "\"><div class=\"block-wait\"></div></div>").appendTo('body');
			$block = $("#" + blockId);
		} else {
			$('#' + blockId +' .block-wait').hide();
		}
		$block.css('z-index', zIndex);

		if (doBlock) {
			$block.css('display', 'block')
				 .fadeTo(0,0)
				 .fadeTo(
				 400,
				 0.6,
				 function() {
					 if (callBack) {
						 callBack();
					 }
				 }
			)
		} else {
			$("#" + blockId).css('display', 'block')
				 .fadeTo(
				 200,
				 0,
				 function() {
					 $("#" + blockId).hide().css('z-index', zIndex);
					 $('#' + blockId + ' .block-wait').html('');
					 if (callBack) {
						 callBack();
					 }
				 }
			)
		}
	},
	showForm : function(iframe) {
		$(iframe)
			 .css('visibility', 'visible')
			 .closest('div.content')
			 .removeClass('loading');

		if (!mailer.buttonsWereCorrected) {
			var $btn = parent.$('#udu-mailer-wnd .bottom-bar .blk-2 button');
			$btn.animate(
				{
					opacity : 1
				}
			);
		}
		mailer.buttonsWereCorrected = true;
	},
	openWindow : function (options) {

		app.$winDiv = $("#udu-mailer-wnd");

		var mailUrl = mailer.mailFormUrl;

		if (app.prodInfo) {
			mailUrl = app.url.formURL(mailUrl, {mode: 'prodDtl'});
		}

		if (app.$winDiv.length === 0) {
			mailer.buttonsWereCorrected = false;
			var winW = $(window).width();
			$("<div id=\"udu-mailer-wnd\">" +
				 "<div class=\"body\">" +
				 "<a href='javascript:mailer.closeWindow()' class=\"close-btn\"></a>" +
				 "<div class=\"bottom-bar\">" +
				 "<div class=\"blk-2\">" +
				 "<button onclick='mailer.doSend()' class='btn-prev'>" + mailer.messages.send + " </button>" +
				 "<button onclick='mailer.closeWindow()'>" + mailer.messages.cancel + "</button>" +
				 "</div>" +
				 "</div>" +
				 "<div class=\"content loading\"><iframe scrolling=\"no\" class='not-loaded iframe-mailer' onload='mailer.showForm(this)' src='" + mailUrl + "'></iframe></div>" +
				 "<div class=\"top-bar\">" + options.title + "</div>" +
				 "</div>" +
				 "</div>")
				 .appendTo('body > ._')
				 .css('left', (winW - 100) / 2 );

			app.$winDiv = $("#udu-mailer-wnd");
			app.$prodContent = app.$winDiv.find('> .body > .content');

			$(window)
				 .resize(
					  mailer.correctDialogSize
				 );
		}

		winW = $(window).width();
		var prodWinW = Math.max(0.7 * winW, 300);

		mailer.blockWindow(true);

		app.$winDiv.stop()
			 .width(prodWinW)
			 .show()
			 .css({
				 height: 100,
				 top: -100,
				 left : Math.max(20, (winW - prodWinW) / 2)
			 })
			 .fadeTo(
				  300,
				  1,
				  mailer.correctDialogSize
			 );

		return app.$winDiv;
	},
	correctSrc : function(inSrc) {
		if (inSrc.indexOf('http:') < 0 && inSrc.indexOf('https:') < 0) {
			if (inSrc.substring(0, 1) == '/') {
				inSrc = inSrc.substring(1, inSrc.length)
			}
			if (this.domainName) {
				if (this.domainName.indexOf('//') >= 0) {
					this.domainName = this.domainName.split('//')[1];
				}
				inSrc = String(document.location.protocol + '//' + this.domainName + (location.port ? ':' + location.port: '') + '/' + inSrc);
			} else {
				inSrc = String(document.location.protocol + '//' + location.hostname + (location.port ? ':' + location.port: '') + '/' + inSrc);
			}
		}
		return inSrc;
	},
	checkEmail : function(email){
		var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;
		return emailPattern.test(email);
	},
	checkForm : function () {
		if (!$('#subject').val()) {
			top.app.alert(mailer.messages['empty_subject']);
			return false;
		}
		var $recipent = $('#recipent');
		if (!$recipent.val()) {
			top.app.alert(mailer.messages['empty_recipent']);
			return false;
		}
		var arr = $recipent.val().split(';');
		for (var i in arr) {
			if (!mailer.checkEmail(arr[i])) {
				var msg = mailer.messages['wrong_email'].replace('%email%', arr[i]);
				top.app.alert   (msg);
				return false;
			}
		}

		return true;
	},
	correctPathes : function(content) {
		var arr = content.match(/(src|href)="[^\"]*"/g);
		if (arr) {
			for (var i in arr) {
				try {
					var src1 = arr[i].replace(/(href=|src=|\")/g, '');
					var src2 = mailer.correctSrc(src1);
					content = content.replace(arr[i], arr[i].replace(src1, src2));
				} catch(e) {}
			}
		}
		return content;
	},
	doSend : function() {

		var win = $('#udu-mailer-wnd iframe').get(0).contentWindow;
		var url = mailer.mailFormUrl.replace('send-mail.html', 'send-mail-process.html');

		if (win.tinyMCE) {
			win.$("textarea.tinyimce").each (
				 function () {
					 var content = '';
					 var $this = $(this);
				 	if (app.tiny) {
				 		content = app.tiny.getContent(this.id)
					} else {
				 		content = win.tinyMCE.get(this.id).getContent();
					}
					 $this[0].value = mailer.correctPathes(content);
				 }
			)
		}

		var postData = win.$('.div-mailer-form form').serializeArray();

		if (!win.mailer.checkForm()) {
			return;
		}
		if(typeof(Storage) !== "undefined") {
			window.localStorage["udu-mailer-last-recipent"] = win.$('#recipent').val();
		}

		top.app.waitBlockZIndex = 9999;

		if (win.mailer.messages['udu_displaySvgPdfLink'] === 'Y') {
			top.app.blockWindow(
				true,
				true,
				function() {
					top.mailer.getSvgPdf(
						function(fileName, fileContent) {
							if (fileName) {
								postData.push({
									name: 'attFileName', value: fileName
								});
								postData.push({
									name: 'attFileContent', value: fileContent.split('base64,')[1]
								});
							}

							$.ajax({
								url : url,
								type: 'POST',
								data : postData,
								success:function(str, textStatus, jqXHR) {
									top.app.waitBlockZIndex = false;
									top.app.alertMsg('_Mailer_Success_Info', null, {replace: {'%count%': str}});
								},
								error: function(jqXHR, textStatus, errorThrown) {
									parent.app.alert('Internal System Error');
								}
							});

						}
					)
				}
			);
		} else {
			top.app.blockWindow(
				true,
				true,
				function() {
					$.ajax({
						url : url,
						type: 'POST',
						data : postData,
						success:function(str, textStatus, jqXHR) {
							top.app.waitBlockZIndex = false;
							top.app.alertMsg('_Mailer_Success_Info', null, {replace: {'%count%': str}});
						},
						error: function(jqXHR, textStatus, errorThrown) {
							parent.app.alert('Internal System Error');
						}
					});
				}
			);
		}


	},
	closeWindow : function() {
        if (!app.$winDiv) {
            return false;
        }
		app.$winDiv.animate({
			top: (-100 - app.$winDiv.height())
		}).find('> .body > .content')
			 .html('')
			 .scrollTop(0);
		app.$winDiv.fadeOut(
			 100,
			 function() {
				 app.$winDiv.remove();
				 mailer.blockWindow(false);
			 }
		);
	},
	correctDialogSize : function() {
		var winH = $(window).height();
		var winW = $(window).width();
		var prodWinH = Math.max(0.8 * winH, 300);
		var prodWinW = Math.max(0.6 * winW, 450);
		$("#udu-mailer-wnd")
			 .css('margin', 16)
			 .stop()
			 .animate({
				 height : prodWinH,
				 width : prodWinW,
				 top : Math.max(20, (winH - prodWinH) / 2),
				 left : Math.max(20, (winW - prodWinW) / 2)
			 });
	},
	correctData : function () {
		$('textarea.tinyimce').each(
			function() {
				var text = $(this).val();
				text = text.replace(/href="(.*\[url\].*)"/g, 'href="[url]"');
				$(this).val(text);
			}
		);
		var tmp = '';
		if(typeof(Storage) !== "undefined") {
			tmp = window.localStorage["udu-mailer-last-recipent"];
			if (tmp && tmp.indexOf('@') > 0) {
				$('#recipent').val(tmp);
			}
		}

		var arr = {};
		var ids = ['subject', 'body'];
		for (var i = 0; i < ids.length; i++) {
			tmp = mailer.getVariables($('#' + ids[i]).val());
			if (tmp) {
				for (var a in tmp) {
					arr[tmp[a]] = String(tmp[a]).replace(/\]|\[/g, '');
				}
			}
		}

		var cl = '';
		var html = '';
		var href = this.correctSrc(parent.mailer.itemOptions['href']);
		for (i in arr) {
			var $item = parent.mailer.$item;
			cl = arr[i];

			if (cl == 'image') {
				var $img = $item.find('img');
				if ($img.length > 0) {
					var src = mailer.correctSrc($img.attr('src'));
					if (src.indexOf('/120/') > 0) {
						src = src.replace('/120/', '/200/')
					}
					html = '<a href="' + href + "\"><img style=\"max-width: 300px\" src=\"" + src + "\" alt=\"" + $img.attr('alt') + "\"></a>";
				} else {
					html = '';
				}
			} else if (cl == 'svg-attach-place') {
				html = '';
				if (parent.svgEditor) {
					var curIdx = parent.svgEditor.currentIndex;
					if (!curIdx) {
						curIdx = 0;
					}
					var $iframe = parent.$('#attachment-' + curIdx).find('iframe');
					if ($iframe.length === 1) {
						html = $iframe[0].contentWindow.$('.svg-wrapper').html();
						//html = '<img src="data:image/svg+xml;base64,' + btoa(html) + '">';
					}
				}
			} else if (cl == 'url') {
				html = href;
			} else if (cl == 'price-table') {
				html = parent.$("div.price-table").html();
				if (!html) {
					html = '';
				} else {
					var $tmpth = parent.$('table.price-table th');
					var brdColor = parent.$('table.price-table td').css('border-left-color');
					html = html.replace('<table', '<table style="border:1px solid ' + brdColor + ';border-collapse: collapse;background-color: #f5f5f5;"');
					html = html.replace(/<td /g, '<td style="border:1px solid ' + brdColor + '; font-size: 12px"');
					html = html.replace(/<th /g, '<th style="border:1px solid ' + brdColor + '; background-color: ' + $tmpth.css('background-color') + ';' +
						'color: ' + $tmpth.css('color') + '; font-size: 12px;font-weight: bold"');
				}
			} else {
				var $div = $item.find('.' + cl);
				if ($div.length > 0) {
					while ($div.find('.' + cl + ', .mail-info').length > 0) {
						$div = $div.find('.' + cl + ', .mail-info');
					}
					if (cl == 'name' && $div.find('a').length > 0) {
						$div = $div.find('a');
					}
					html = $div.html().replace(/•/g, '&#8226;');
				} else if (cl === 'price') {
					var $priceItem = parent.$('table.price-table tr:last-child td:last-child');
					if ($priceItem.length === 0) {
						$priceItem = parent.$('._ .single-price-value .money');
					}
					html = $priceItem.html().replace(/•/g, '&#8226;');
					if (!html) {
						html = '';
					}
				} else {
					html = '';
				}
			}
			for (i = 0; i < ids.length; i++) {
				$item = $('#' + ids[i]);
				if (ids['i'] == 'body') {
					tmp = $item.html();
				} else {
					tmp = $item.val();
				}

				var regExp = new RegExp('\\[' + cl + '\\]', 'g');

				if (ids['i'] == 'body') {
					$item.html(tmp.replace(regExp, html));
				} else {
					$item.val(tmp.replace(regExp, html));
				}
			}
		}
	},
	getVariables : function(str) {
		var tmp = str.split('[');
		var res = [];
		for (var i in tmp) {
			if (tmp[i].indexOf(']') < 0) {
				continue;
			}
			res[res.length] = tmp[i].split(']')[0];
		}
		return res;
	},
	correctTinyImceHeight : function() {
		$('#body_ifr, td.mceIframeContainer').height($(window).height() - 140);
	},
	setTinyEditor : function() {
		var $txt = $('textarea.tinyimce');

		if ($txt.length > 0) {
			if (window.app && app.tiny) {
				// use new tiny
				app.tiny.init($txt);
				return;
			}
		}
		$(window)
			.resize(
				mailer.correctTinyImceHeight
			);
		$txt.each(
			 function() {
				 tinyMCE.init({
					 // General options
					 mode : "exact",
					 theme : "advanced",
					 elements : this.id,
					 plugins : "autolink,lists,pagebreak,style,layer,table,save,advhr,advimage,advlink,emotions,iespell,inlinepopups,insertdatetime,preview,media,searchreplace,print,contextmenu,paste,directionality,fullscreen,noneditable,visualchars,nonbreaking,xhtmlxtras,template,wordcount,advlist,autosave,visualblocks",
					 width: "100%",
					 height: "240",
					 relative_urls: false,
					 // Theme options
					 theme_advanced_buttons1 : "newdocument,|,bold,italic,underline,strikethrough,|,justifyleft,justifycenter,justifyright,justifyfull,|cut,copy,paste,pastetext,pasteword",
					 theme_advanced_toolbar_location : "top",
					 theme_advanced_toolbar_align : "left",
					 theme_advanced_statusbar_location : "bottom",
					 theme_advanced_resizing : true,

					 extended_valid_elements: "svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*],ellipse[*],polygon[*],polyline[*],linearGradient[*],radialGradient[*],stop[*],image[*],view[*],text[*],textPath[*],title[*],tspan[*],glyph[*],symbol[*],switch[*],use[*]",

					 autoresize_min_height: 400,
					 autoresize_max_height: 800,
					 oninit : function() {
						 mailer.correctTinyImceHeight();
					 }
				 });
			 }
		)
	}
};
